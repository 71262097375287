import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"

export default function Page({ data }) {
  const page = data.allWpPage.nodes[0]
  return (
    <Layout>
      <Seo title={page.title}/>
      <h1 className="pt--50">{page.title}</h1>
      <div className="pb--50" dangerouslySetInnerHTML={{ __html: page.content }} />
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpPage(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        content
      }
    }
  }
`